<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>افزودن رسانه</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <div
            class="w-100 dropzone p-4 flex d-flex flex-column align-items-center justify-content-center"
            @click="openFileBrowser"
          >
            <p>جهت آپلود فایل کلیک کنید.</p>
            <input
              @change="convertImageFileToBase64"
              accept="image/*"
              type="file"
              style="display: none"
              ref="filePicker"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "افزودن رسانه به کتابخانه",
  data() {
    return {
      isLoading: false,
      fileDetails: {
        base64: null,
        objectId: 0,
        type: 10, // 10 is for library
        priority: null,
      },
    };
  },
  methods: {
    async convertImageFileToBase64(event) {
      this.fileDetails.base64 = await Helper.readAsDataURL(
        event.target.files[0]
      );
      this.sendCreateFileRequest();
    },
    
    openFileBrowser() {
      this.$refs.filePicker.click();
    },
    async sendCreateFileRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let createFile = new CreateFile(_this);
        createFile.setRequestParamDataObj(_this.fileDetails);
        await createFile.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فایل آپلود شد.`,
              },
            });
            _this.fileDetails = {
              base64: null,
              objectId: 0,
              type: 10, // 10 is for library
              priority: null,
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
  },
};
import { CreateFile } from "@/libs/Api/Media";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
</script>
<style scoped>
.dropzone {
  min-height: 30vh;
  border: 1px #000000 dashed !important;
  cursor: pointer;
}
</style>
